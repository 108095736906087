<template>
  <div class="bg-gray-200 text-center rounded-2lg px-2 py-4">
    <app-sub-heading
      class="mb-2"
      size="text-md"
    >
      <app-icon
        library="coolicon"
        name="cookie"
        class="ml-1"
        size="text-md"
      />

      {{ t('ttmt.cookies.notice.title') }}
    </app-sub-heading>

    <p class="text-sm">
      {{ t('ttmt.cookies.notice.needed', { name: name }) }}
    </p>

    <p class="text-sm">
      {{ t('ttmt.cookies.notice.refresh') }}
    </p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

defineProps({
  // Cookie name
  name: {
    type: String,
    default: 'undefined',
  },
})

const { t } = useI18n()
</script>
