<template>
  <app-heading class="mb-3">
    {{ t('ttmt.excursions.store.heading') }}
  </app-heading>

  <excursion-form
    v-if="!store.state.spinner.active"
    :resource="excursion"
    :additional-errors="excursionFormErrors"
    :submitting="excursionFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { set } from 'lodash'
import { useRoute } from 'vue-router'

import {
  fetchPointsOfInterest,
  fetchLastTravellerTravellerFolder as apiFetchLastTravellerTravellerFolder,
} from '@shared/http/api'
import AppHeading from '@app/components/ui/AppHeading.vue'
import ExcursionForm from '@app/components/resources/excursion/ExcursionForm.vue'
import useAppRouter from '@shared/hooks/router'
import useExcursionForm from '@shared/hooks/resources/form/excursion'

const store = useStore()
const appRouter = useAppRouter()
const route = useRoute()
const { t } = useI18n()

const {
  resource: excursion,
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
})

function onSubmitSuccess() {
  appRouter.back()
  store.commit(
    'flashes/ADD_FLASH',
    {
      message: t('ttmt.excursions.store.success'),
      type: 'success',
    },
  )
}

function fetchPois() {
  return new Promise((resolve) => {
    // Get poi ids from route query params
    const points_of_interest_ids = []

    // Get poi id from query (add excursion action)
    if (route.query.point_of_interest_id) {
      points_of_interest_ids.push(route.query.point_of_interest_id)
    }

    // If a traveller folder is active, get butler's POI
    if (travellerFolder.value?.relationships?.point_of_interest?.id) {
      points_of_interest_ids.push(travellerFolder.value.relationships.point_of_interest.id)
    }

    if (points_of_interest_ids.length > 0) {
      fetchPointsOfInterest({
        'filter[id]': points_of_interest_ids.join(','),
        'mode': 'index',
      })
        .then((response) => {
          // Prefill relationships with poi resources
          set(
            excursion.value,
            'relationships.excursions_points_of_interest',
            response.data.data?.map((poi, index) => ({
              relationships: {
                point_of_interest: poi,
              },
              attributes: {
                position: index,
              },
            })) ?? [],
          )
        })
        .finally(() => {
          resolve()
        })
    } else {
      resolve()
    }
  })
}

const travellerFolder = ref()

function fetchTravellerFolder() {
  return new Promise((resolve) => {
    apiFetchLastTravellerTravellerFolder()
      .then((response) => {
        travellerFolder.value = response.data.data
      })
      .finally(() => {
        resolve()
      })
  })
}

async function fetchFormRelationResources() {
  store.commit('spinner/ENABLE')

  // folder need to be fetched before the points of interest
  // because folder's point will be included in points of interest options list
  await fetchTravellerFolder()
  await fetchPois()

  store.commit('spinner/DISABLE')
}

fetchFormRelationResources()
</script>
