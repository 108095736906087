<template>
  <p class="mb-3.5 font-bold">
    {{ (t('ttmt.user_geoloc_settings.geolocation.intro')) }}
  </p>

  <form-group
    name="kind"
    type="radio"
    :hint="selectedKind
      ? t(
        `ttmt.form.hints.geoloc_setting.kind.app.${selectedKind}`,
        { title: getAttributeTranslation(store.state.auth.user.relationships?.staying_hotel?.attributes?.title)},
      )
      : null"
    :label="false"
    :form-control-props="{
      options: kindsOptions,
    }"
    @changed="handleKindChange"
  />

  <form-group
    v-if="selectedKind === 'customized'"
    name="filter"
    :label="t('ttmt.form.labels.geoloc_setting.where_would_you_like_to_go')"
    type="select"
    rules="required"
    :form-control-props="{
      filterable: false,
      options: handleGeolocFilterSearch,
      minChars: 3,
      searchHeaderText: t('ttmt.form.hints.geoloc_setting.filter_header'),
    }"
    @changed="handleGeolocFilterChange"
  >
    <template #control-vue-multiselect-option="option">
      <div>
        <template v-if="option.icon">
          <font-awesome-icon :icon="option.icon" />
          &nbsp;
        </template>

        <strong>
          {{ option.label }}
        </strong>

        <template v-if="option.subLabel">
          <br>
          <em class="text-sm">
            {{ option.subLabel }}
          </em>
        </template>
      </div>
    </template>
  </form-group>

  <app-heading
    class="my-3"
  >
    {{ capitalize(t('ttmt.common.more_options')) }}
  </app-heading>

  <form-group
    name="latitude"
    type="hidden"
  />

  <form-group
    name="longitude"
    type="hidden"
  />

  <form-group
    name="country_code"
    type="hidden"
  />

  <form-group
    name="measurement_system"
    :label="t('ttmt.form.labels.geoloc_setting.measure_unit')"
    type="radio"
    :form-control-props="{
      options: measureUnitOptions,
    }"
  />

  <form-group
    :initial-value="resource.attributes.radius !== null"
    name="limit_to_a_radius"
    :label="radiusLabelToUse"
    :submittable="false"
    type="switch"
  />

  <form-group
    v-if="limitToARadiusValue"
    class="top-5 relative"
    :initial-value="resource.attributes.radius || 10"
    name="radius"
    :label="false"
    type="slider"
    rules="min.numeric:1|required"
    :form-control-props="{
      min: 1,
      max: maxRadius,
      formatSliderTooltips: formatSliderTooltips,
    }"
  />

  <form-group
    v-else
    name="radius"
    :initial-value="null"
    type="hidden"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import useUserGeolocSetting from '@shared/hooks/userGeolocSetting'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import useLocale from '@shared/hooks/locale'
import FormGroup from '@shared/components/form/FormGroup.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const store = useStore()

const { getAttributeTranslation } = useLocale()
const {
  kindsOptions,
  selectedKind,
  limitToARadiusValue,
  radiusLabelToUse,
  maxRadius,
  formatSliderTooltips,
  handleGeolocFilterSearch,
  handleGeolocFilterChange,
  handleKindChange,
} = useUserGeolocSetting(props)
const {
  measureUnitOptions,
} = useSelectOptions()

</script>
