<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset
      :disabled="submitting"
    >
      <form-group
        rules="required|email"
        class="with-border-on-control"
        name="email"
      />

      <form-group
        name="locale"
        rules="required"
        type="select"
        :label="t('ttmt.form.labels.traveller_invitation.locale')"
        class="h-56 with-border-on-control"
        :form-control-props="{
          options: staticLocalesOptions,
        }"
      />

      <app-button
        feature="confirm"
        type="submit"
        :disabled="submitting"
        :loading="submitting"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
} = useForm(props, { emits })

const { t } = useI18n()

// ---------- LOCALES ----------

const {
  staticLocalesOptions,
} = useLocale()
</script>
