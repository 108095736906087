<template>
  <app-heading class="mb-3">
    {{ t('ttmt.excursions.update.heading') }}
  </app-heading>

  <excursion-form
    v-if="excursion && !store.state.spinner.active"
    :resource="excursion"
    :additional-errors="excursionFormErrors"
    :submitting="excursionFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import {
  fetchExcursion as fetchApiExcursion,
} from '@shared/http/api'
import useAppRouter from '@shared/hooks/router'
import useExcursionForm from '@shared/hooks/resources/form/excursion'
import AppHeading from '@app/components/ui/AppHeading.vue'
import ExcursionForm from '@app/components/resources/excursion/ExcursionForm.vue'

const store = useStore()
const appRouter = useAppRouter()
const route = useRoute()
const { t } = useI18n()

const excursion = ref({})

function fetchExcursion() {
  store.commit('spinner/ENABLE')
  const { id } = route.params

  fetchApiExcursion(
    id,
    { 'mode': 'edit' },
  )
    .then((response) => {
      excursion.value = response.data.data
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

const {
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
  resource: excursion,
})

function onSubmitSuccess() {
  appRouter.back()
  store.commit(
    'flashes/ADD_FLASH',
    {
      message: t('ttmt.excursions.update.success'),
      type: 'success',
    },
  )
}

fetchExcursion()
</script>
