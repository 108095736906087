<template>
  <div class="flex items-center justify-between">
    <app-heading>
      {{ l10nTitle }}
    </app-heading>

    <app-ugozer-icon
      v-if="resource?.attributes.kind === 'ugozer_app'"
      mode="show"
    />

    <app-favorite-icon
      v-if="resource?.attributes.favorite"
      mode="show"
    />
  </div>

  <template v-if="pointsOfInterest.length > 0">
    <app-card-list
      :list="pointsOfInterest.map((poi) => ({ resource: poi, hideActions: true }))"
      :component="PointOfInterestHorizontalCard"
    />
  </template>

  <!-- Embed Map -->
  <app-embed-direction-map
    v-if="directionCoordinates.length >= 2"
    :coordinates="directionCoordinates"
    class="w-full max-w-screen-sm mx-auto mb-4"
  />

  <div
    :class="actionsMarginBottom"
  >
    <excursion-actions
      :resource="resource"
      class="justify-center"
      big-icons
      @destroyed="destroyCallback"
    />
  </div>

  <point-of-interest-butler-card
    v-if="hasButlersPoi"
    :resource="butlersPoi"
  />
</template>

<script setup>
import { computed } from 'vue'

import useExcursion from '@shared/hooks/resources/excursion'
import AppCardList from '@shared/components/ui/card/AppCardList.vue'
import AppEmbedDirectionMap from '@shared/components/ui/map/direction/AppEmbedDirectionMap.vue'
import AppFavoriteIcon from '@app/components/ui/AppFavoriteIcon.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppUgozerIcon from '@app/components/ui/AppUgozerIcon.vue'
import ExcursionActions from '@app/components/resources/excursion/ExcursionActions.vue'
import PointOfInterestButlerCard from '@app/components/resources/point_of_interest/PointOfInterestButlerCard.vue'
import PointOfInterestHorizontalCard from '@app/components/resources/point_of_interest/PointOfInterestHorizontalCard.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // User traveller folder JSON API resource
  folderResource: {
    type: Object,
    default: () => ({}),
  },
  // Function called
  // on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
})

defineEmits([
  'destroyed',
])

const {
  l10nTitle,
  directionCoordinates,
  pointsOfInterest: allPointsOfInterest,
} = useExcursion(props)

// ---------- POI ----------

const pointsOfInterest = computed(() => (
  allPointsOfInterest.value.filter((poi) => (
    poi.id !== butlersPoi.value?.id
  )) || []
))

const hasButlersPoi = computed(() => (
  allPointsOfInterest.value.find((poi) => (
    poi.id === butlersPoi.value?.id
  ))
))

const butlersPoi = computed(() => (
  props.folderResource?.relationships?.point_of_interest
))

const actionsMarginBottom = computed(() => (
  hasButlersPoi.value
    ? 'mb-28'
    : 'mb-0'
))
</script>
