<template>
  <app-cookie-placeholder
    v-if="!gmapsAuthorized"
    :name="t('ttmt.cookies.services.gmaps')"
  />

  <div
    v-else
  >
    <iframe
      v-if="coordinates.length >= 2"
      id="directionMap"
      allowfullscreen
      loading="lazy"
      :class="`w-full ${height} mb-4 rounded-2lg shadow-lg shadow-theme-500/10 bg-white gmap`"
      :src="embedMapUrl"
    />

    <p class="text-center font-bold text-sm mb-1">
      {{ t('ttmt.excursions.direction.display_mode') }}
    </p>

    <div class="flex justify-center items-center">
      <app-embed-direction-map-mode-choice
        v-for="(modeProps, i) in modesProps"
        :key="i"
        v-bind="modeProps"
        class="first:rounded-l-full last:rounded-r-full"
        :selected="modeProps.mode === selectedMode"
        @selected="handleModeChanged"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { fromJS } from 'immutable'
import { first, last } from 'lodash'

import useCookies from '@shared/hooks/cookies'
import AppEmbedDirectionMapModeChoice from '@shared/components/ui/map/direction/AppEmbedDirectionMapModeChoice.vue'
import AppCookiePlaceholder from '@shared/components/ui/AppCookiePlaceholder.vue'

const props = defineProps({
  // Array of object with "latitude" and "longitude" attributes
  coordinates: {
    type: Array,
    default: () => ([]),
  },
  // Force specific height
  height: {
    type: String,
    default: 'h-96 lg:h-120',
  },
})

// Google Embed Map API documentation:
// URL: https://developers.google.com/maps/documentation/embed/embedding-map#directions_mode

const gmapsBaseUrl = 'https://www.google.com/maps'
const gmapsEmbedBaseUrl = `${gmapsBaseUrl}/embed/v1/directions`
const gmapsEmbedApiKey = import.meta.env.VITE_GOOGLE_MAPS_EMBED_API_KEY

const embedMapUrl = computed(() => (
  `${gmapsEmbedBaseUrl}?${routeParam.value}&key=${gmapsEmbedApiKey}&mode=${selectedMode.value}`
))

// ---------- COORDINATES PARAM ----------

const routeParam = computed(() => {
  // Start building param
  let param = `${originParam.value}&${destinationParam.value}`

  // Add waypoints if present
  if (waypointsParam.value) {
    param += `&${waypointsParam.value}`
  }

  return param
})

const originCoordinates = computed(() => (
  first(props.coordinates)
))

const originParam = computed(() => (
  `origin=${originCoordinates.value.latitude},${originCoordinates.value.longitude}`
))

const destinationCoordinates = computed(() => (
  last(props.coordinates)
))

const destinationParam = computed(() => (
  `destination=${destinationCoordinates.value.latitude},${destinationCoordinates.value.longitude}`
))

const waypointsCoordinates = computed(() => {
  const coordinatesCopy = fromJS(props.coordinates).toJS()

  // Remove first & last coordinates
  coordinatesCopy.shift()
  coordinatesCopy.pop()

  return coordinatesCopy.slice(0, 20) // Only 20 waypoints are allowed by Google
})

const waypointsParam = computed(() => {
  if (waypointsCoordinates.value.length > 0) {
    const waypointsParams = waypointsCoordinates.value.map((coordinate) => (
      `${coordinate.latitude},${coordinate.longitude}`
    ))

    // Separate each waypoint param with a pipe
    return `waypoints=${waypointsParams.join('|')}`
  }
  return null
})

// ---------- MODE ----------

const modesProps = [
  {
    mode: 'driving',
    iconProps: {
      library: 'fontawesome',
      name: 'car',
    },
  },
  {
    mode: 'walking',
    iconProps: {
      library: 'fontawesome',
      name: 'person-walking',
    },
  },
  {
    mode: 'bicycling',
    iconProps: {
      library: 'fontawesome',
      name: 'bicycle',
    },
  },
]

const selectedMode = ref('walking')

function handleModeChanged(newMode) {
  selectedMode.value = newMode
}

// ---------- COOKIE ----------

const { t } = useI18n()
const { getCookieSetting } = useCookies()

const gmapsAuthorized = computed(() => (
  getCookieSetting('gmaps')
))
</script>
